// 发票管理
import request from '@/utils/request'

// 新增
export function addInvoice () {
   return request({
      url: "",
      method: "",
      data
   })
}

// 查询分页
export function getListPage(params) {
   return request({
       url: "/api/invoiceRecord/listPage",
       method: "get",
       params
   })
}

// 查询初始化
export function getPageInit() {
   return request({
     url: "/api/invoiceRecord/pageInit", 
     method: "get"
   })
}

// 开票确认
export function invoiceRecord(data) {
   return request({
      url: "/api/invoiceRecord",
      data,
      method: "put"
   })
}

// 审核确认
export function checkRecord(data) {
   return request({
     url: "/api/invoiceRecord",
     data,
     method: "put"
   })
}

// 作废事件
export function invalidRecord(data) {
   return request({
      url: "/api/invoiceRecord",
      data,
      method: "put"
   })
}